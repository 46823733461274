body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.smol-text {
  font-size: 12px !important;
  .table th, .table td{
    padding: .25rem
  }
  .btn-sm{
    padding: .1rem .5rem;
    font-size: 8px;
  }
}
.w-100{
  width: 100% !important;
}

.no-break{
  white-space: nowrap !important;
}

.font-16{
  font-size: 16px !important;
  td{
    font-size: 16px !important;

  }
}

aside.ant-layout-sider{
  position: sticky;
  top: 0;
}