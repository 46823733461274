.avatar {
    border-radius: 100%;
    max-width: 115px;
}
// .custom-container {
//     width: 1240px;
//     margin: 0 auto;
// }
// .border-right-gray {
//     border-right: 1px solid rgba(128, 128, 128, 0.767);
// }
.rounded-square {
    height: 115px;
    width: 115px;
}
.custom-card {
    border-radius: 8px;
    color: #ffffff;
}
